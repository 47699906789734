import React from 'react';
import Photography, { PhotographyPropTypes } from './Photography';

const Index = (props: PhotographyIndexProps) => {
    const { error, albums, keys } = props;

    if (!error) {
        return <Photography albums={albums} keys={keys} />;
    } else {
        console.error(error);
        throw new Error('Error loading Photography Page content. Please check console');
    }
};

export interface PhotographyIndexProps extends PhotographyPropTypes {
    error?: any;
}

export default Index;
