import React from 'react';
import { getBehindTheScenesImages, getCollaboratorImages } from 'lib/api/media';
import Collaboration from './Collaboration';
import { VideoMeta } from 'types/media';

const Index = ({ featuredVideo }: CollaborationIndexProps) => {
    const collaborators = getCollaboratorImages();
    const behindTheScenes = getBehindTheScenesImages();

    return (
        <Collaboration
            featuredVideo={featuredVideo}
            collaborators={collaborators}
            behindTheScenes={behindTheScenes}
        />
    );
};

export type CollaborationIndexProps = {
    featuredVideo: VideoMeta;
};

export default Index;
