import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { PhotographyAlbum } from 'types/media';
import { ImageMasonryGrid } from 'organisms';
import { useRouter } from 'next/router';
import { BackButton } from 'atoms';
import { CallToAction } from 'molecules';
import clsx from 'clsx';
import useStyles from './styles';
import { Skeleton } from '@material-ui/lab';

const Gallery = (props: GalleryProps) => {
    const { album } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const router = useRouter();
    const classes = useStyles();

    const [artificialLoad, setArtificialLoad] = React.useState(true);

    // Artificially set timeout onLoad
    React.useEffect(() => {
        let timeout: NodeJS.Timeout;

        // If this page not been statically generated yet,
        // display loading page indefinitely until getStaticProps resolves
        if (!router.isFallback) {
            setTimeout(() => {
                setArtificialLoad(false);
            }, 500);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.mobileMargin}>
                    <BackButton>Back</BackButton>
                </div>

                <div className={clsx(classes.titleContainer, classes.mobileMargin)}>
                    {artificialLoad ? (
                        <Skeleton
                            variant={'text'}
                            height={'72px'}
                            width={isMobile ? '75%' : '40%'}
                        />
                    ) : (
                        <Typography variant={'h3'} component={'h1'}>
                            {album.title}
                        </Typography>
                    )}
                    {artificialLoad ? (
                        <Skeleton
                            variant={'text'}
                            height={'20px'}
                            width={isMobile ? '50%' : '30%'}
                        />
                    ) : (
                        <Typography variant={'body1'} color={'textSecondary'}>
                            {album.desc}
                        </Typography>
                    )}
                </div>

                <ImageMasonryGrid
                    images={album ? album.images : []}
                    albumName={album ? album.title : ''}
                    loading={artificialLoad}
                />
                <div className={classes.ctaContainer}>
                    <CallToAction />
                </div>
            </div>
        </div>
    );
};

export interface GalleryProps {
    album?: PhotographyAlbum;
}

export default Gallery;
