import React, { useEffect, useRef, useState } from 'react';
import { Box, Tab, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import TabPanel from '@material-ui/lab/TabPanel';
import { Paper } from 'atoms';
import { FormikProps } from 'formik';
import { sendEmail } from 'lib/api/mailgun';
import { CallToAction, ContactDialog, FeaturedVideo } from 'molecules';
import { ContactFormValues } from 'molecules/ContactDialog/ContactDialog';
import { useSnackbar } from 'notistack';
import {
    AboutTheCreatorCard,
    CollaboratorsScrollingTicker,
    ImageMasonryGrid,
} from 'organisms';
import { CollaboratorResponse } from 'types/response';
import { ImageMeta, VideoMeta } from 'types/media';
import clsx from 'clsx';
import useStyles from './styles';

const Collaboration = (props: CollaborationProps) => {
    const { featuredVideo, collaborators, behindTheScenes } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [artificialLoad, setArtificialLoad] = useState(true);
    const [showContactDialog, setShowContactDialog] = useState(false);
    const [activeTabValue, setActiveTabValue] = useState('1');

    // Maintain an element ref to a desired anchor position on the page such that we can
    // continue scroll to this position whenever the tab panel changes
    const scrollAnchorRef = useRef(null);
    const mountedRef = useRef(false);

    // You can pass any props down to the useStyles() function as useStyles({prop: value})
    // they will be available in your styles function
    const classes = useStyles();

    // Artificially set timeout onLoad
    useEffect(() => {
        let timeout = setTimeout(() => {
            setArtificialLoad(false);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        /**
         * Adapted from: https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
         *
         * Here we prevent useEffect from running its designated code-block after initial render.
         * This will prevent the browser from scrolling to scrollAnchor position (above the tab navigator)
         * When the page finishes rendering. We only want this to trigger when the active tab changes
         */
        if (!scrollAnchorRef || !mountedRef.current) {
            mountedRef.current = true;
            return;
        }

        try {
            const newScrollTop =
                window.scrollY +
                scrollAnchorRef.current.getBoundingClientRect().top -
                100;

            // Safari does not support smooth behavior
            window.scrollTo({ top: newScrollTop, left: 0, behavior: 'smooth' });
        } catch (error) {
            console.warn(
                'Failed to scroll to top on activeTabValueChange. Window may not be defined:',
            );
            console.warn(error);
        }
    }, [activeTabValue]);

    const handleActiveTabChange = (event: React.ChangeEvent, newValue: string) => {
        setActiveTabValue(newValue);
    };

    const onSubmitContactForm = async (
        values: ContactFormValues,
        formikBag: FormikProps<ContactFormValues>,
        closeModal: Function,
    ) => {
        const { setSubmitting, resetForm } = formikBag;
        const { name, email, subject, body } = values;

        try {
            const response = await sendEmail(name, email, subject, body);

            if (response.status !== 200) {
                throw new Error(
                    `Failed to send email. Please check POST Request. Status code was: ${response.status}`,
                );
            }

            resetForm();

            enqueueSnackbar('Message sent', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Failed to send email. Please try again!', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        } finally {
            setSubmitting(false);
            closeModal();
        }
    };

    const toggleContactForm = () => {
        setShowContactDialog((prevState) => !prevState);
    };

    const renderBehindTheScenes = () => {
        return (
            <div className={classes.aboutTheAuthorCard}>
                <div className={classes.behindTheScenesScrollContainer}>
                    <div className={classes.maxWidthContentContainer}>
                        <ImageMasonryGrid
                            loading={artificialLoad}
                            images={behindTheScenes.map((image) => ({
                                url: image.imageSrc,
                            }))}
                            albumName={''}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderCollaborators = () => {
        return (
            <>
                <div className={classes.collaboratorSectionContainer}>
                    {artificialLoad ? (
                        <>
                            <Skeleton>
                                <Typography
                                    variant={'h4'}
                                    component={'h2'}
                                    gutterBottom
                                    align={'center'}
                                >
                                    Collaboration is King !
                                </Typography>
                            </Skeleton>
                            <Skeleton>
                                <Typography
                                    variant={'body1'}
                                    gutterBottom
                                    align={'center'}
                                >
                                    check out some of my awesome clients
                                </Typography>
                            </Skeleton>
                        </>
                    ) : (
                        <>
                            <Typography variant={'h2'} component={'h3'} align={'center'}>
                                Collaboration is King !
                            </Typography>
                            <Typography variant={'body1'} gutterBottom align={'center'}>
                                check out some of my awesome clients
                            </Typography>
                        </>
                    )}
                </div>
                <div className={classes.collaboratorTicker}>
                    <CollaboratorsScrollingTicker
                        firstRowImages={collaborators.firstRow}
                        secondRowImages={collaborators.secondRow}
                        loading={artificialLoad}
                    />
                </div>
            </>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {artificialLoad ? (
                    <Skeleton variant={'rect'} width={'100%'}>
                        <div className={classes.featuredVideoSkeletonContainer}>
                            <div className={classes.featuredVideoSkeleton} />
                        </div>
                    </Skeleton>
                ) : (
                    <FeaturedVideo
                        videoUrl={featuredVideo.videoLink}
                        thumbnailSrc={featuredVideo.previewImage.url}
                        asBanner
                    />
                )}

                <AboutTheCreatorCard
                    onContactClick={toggleContactForm}
                    loading={artificialLoad}
                />

                {/* Used to anchor scroll position on tab change */}
                <div ref={scrollAnchorRef} />
                <TabContext value={activeTabValue}>
                    <Paper
                        surfaceColor={'atSurface'}
                        className={classes.stickyTabNavigator}
                    >
                        {artificialLoad ? (
                            <div className={classes.skeletonTabLabelContainer}>
                                <Box paddingRight={2}>
                                    <Skeleton
                                        variant={'text'}
                                        height={'40px'}
                                        width={'120px'}
                                    />
                                </Box>
                                <Skeleton
                                    variant={'text'}
                                    height={'40px'}
                                    width={'120px'}
                                />
                            </div>
                        ) : (
                            <TabList
                                onChange={handleActiveTabChange}
                                aria-label="Profile Tab Navigator"
                                centered
                                indicatorColor={'primary'}
                                textColor={'primary'}
                                variant={isMobile ? 'fullWidth' : 'standard'}
                            >
                                <Tab label={'About'} value={'1'} />
                                <Tab label={'Behind The Scenes'} value={'2'} />
                            </TabList>
                        )}
                    </Paper>

                    <TabPanel value={'1'} className={classes.removeTabPanelPadding}>
                        {renderCollaborators()}
                    </TabPanel>
                    <TabPanel value={'2'} className={classes.removeTabPanelPadding}>
                        {renderBehindTheScenes()}
                    </TabPanel>
                </TabContext>

                <div
                    className={clsx(
                        classes.ctaContainer,
                        classes.maxWidthContentContainer,
                    )}
                >
                    {artificialLoad ? (
                        <Skeleton
                            variant={'rect'}
                            width={'100%'}
                            height={isMobile ? 250 : 340}
                        />
                    ) : (
                        <CallToAction
                            callToActionButtonText={`Contact Me`}
                            onCallToActionButtonClick={toggleContactForm}
                        />
                    )}
                </div>
            </div>
            <ContactDialog
                open={showContactDialog}
                onClose={() => {
                    setShowContactDialog(false);
                }}
                onSubmitContactForm={onSubmitContactForm}
            />
        </div>
    );
};

export type CollaborationProps = {
    featuredVideo: VideoMeta;
    collaborators: CollaboratorResponse;
    behindTheScenes: Array<ImageMeta>;
};

export default Collaboration;
