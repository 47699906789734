import React from 'react';
import Gallery, { GalleryProps } from './Gallery';

const Index = (props: GalleryIndexProps) => {
    const { error, album } = props;

    if (error) {
        console.error(error);
        throw new Error('Error loading Photography Page content. Please check console');
    }

    return <Gallery album={album} />;
};

export interface GalleryIndexProps extends GalleryProps {
    error?: any;
}

export default Index;
