import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { BaseLink, Button } from 'atoms';
import useStyles from './styles';

const ServerError = (props: ServerErrorPropTypes) => {
    const { imageSrc, title, description, textContentPadding } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.contentContainer}>
                <div className={classes.imageContainer}>
                    <img src={imageSrc} className={classes.image} />
                </div>
                <Box paddingTop={textContentPadding}>
                    <Typography align={'center'} variant={'h3'} gutterBottom>
                        {title}
                    </Typography>
                    <Typography
                        align={'center'}
                        variant={'body1'}
                        color={'textSecondary'}
                    >
                        {description}
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            component={BaseLink}
                            href={'/'}
                        >
                            Take me home
                        </Button>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export type ServerErrorPropTypes = {
    imageSrc: string;
    title: string;
    description: string;
    textContentPadding?: number;
};

export default ServerError;
