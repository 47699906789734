import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        content: {
            padding: theme.spacing(4, 0),
            maxWidth: theme.breakpoints.values.lg,
            width: '100%',
        },
        ctaContainer: {
            padding: theme.spacing(3, 0),
        },
        tabPanel: {
            paddingTop: theme.spacing(3),
            padding: 0,
        },
        stickyTabNavigator: {
            // TODO: Test browswer compatability of this
            position: 'sticky',
            top: '116px',
            backgroundColor: theme.palette.background.default,
            zIndex: 10,
        },
    });

export default makeStyles(styles);
