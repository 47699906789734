import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100vh',
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        contentContainer: {
            margin: '0 auto',
            maxWidth: theme.breakpoints.values.sm,
            paddingTop: '3rem',
            width: '100%',
        },
        imageContainer: {
            position: 'relative',
            paddingTop: '75%',
            overflow: 'hidden',
        },
        image: {
            height: 'auto',
            position: 'absolute',
            bottom: 0,
            right: 0,
            top: 0,
            left: 0,
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: theme.spacing(3),
        },
    });

export default makeStyles(styles);
