import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        content: {
            padding: theme.spacing(4, 0),
            maxWidth: theme.breakpoints.values.lg,
            height: '100%',
            width: '100%',
        },
        ctaContainer: {
            padding: theme.spacing(3, 0),
        },
        titleContainer: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),
        },
        mobileMargin: {
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(0, 2),
            },
        },
    });

export default makeStyles(styles);
