import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { CallToAction, FeaturedVideo, VideoPlayerModal } from 'molecules';
import { VideoTeaserGrid } from 'organisms';
import { VideoMeta } from 'types/media';
import useStyles from './styles';

const Filmography = (props: FilmographyPropTypes) => {
    const { featuredVideo, videos, keys } = props;

    const classes = useStyles();

    const [artificialLoad, setArtificialLoad] = React.useState(true);
    const [videoModalOpen, setVideoModalOpen] = React.useState(false);
    const [focusedVideo, setFocusedVideo] = React.useState({
        videoUrl: '',
        title: '',
        description: '',
    });

    React.useEffect(() => {
        let timeout = setTimeout(() => {
            setArtificialLoad(false);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const toggleVideoModal = () => {
        setVideoModalOpen((prevState) => !prevState);
    };

    const handleClickVideoThumbnail = (
        videoLink: string,
        title: string,
        description: string,
    ) => {
        setFocusedVideo({ videoUrl: videoLink, title, description });
        toggleVideoModal();
    };

    const renderFeaturedVideo = () => {
        if (artificialLoad) {
            return (
                <div className={classes.sectionContainer}>
                    <Skeleton variant={'rect'} width={'100%'}>
                        <div className={classes.featuredVideoSkeletonContainer}>
                            <div className={classes.featuredVideoSkeleton} />
                        </div>
                    </Skeleton>
                </div>
            );
        } else {
            return (
                <div className={classes.sectionContainer}>
                    <FeaturedVideo
                        videoUrl={featuredVideo.videoLink}
                        thumbnailSrc={featuredVideo.previewImage.url}
                    />
                </div>
            );
        }
    };

    const renderVideoTeaserGrid = (categoryKey: string) => {
        return (
            <div key={categoryKey} className={classes.sectionContainer}>
                <VideoTeaserGrid
                    onClickVideoThumbnail={handleClickVideoThumbnail}
                    loading={artificialLoad}
                    gridTitle={categoryKey}
                    videos={videos[categoryKey]}
                />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <VideoPlayerModal
                open={videoModalOpen}
                onClose={toggleVideoModal}
                onShareClick={() => {}}
                title={focusedVideo.title}
                description={focusedVideo.description}
                aspectRatio={'standard'}
                videoUrl={focusedVideo.videoUrl}
            />

            <div className={classes.content}>
                {renderFeaturedVideo()}
                {keys.map((categoryKey: string) => {
                    return renderVideoTeaserGrid(categoryKey);
                })}

                <div className={classes.ctaContainer}>
                    <CallToAction />
                </div>
            </div>
        </div>
    );
};

export type AllVideos = {
    [key: string]: Array<VideoMeta>;
};

export type FilmographyPropTypes = {
    featuredVideo: VideoMeta;
    videos: AllVideos;
    keys: Array<string>;
};

export default Filmography;
