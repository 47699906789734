import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { ASPECT_RATIOS } from 'config/Media';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        content: {
            padding: theme.spacing(4, 0),
            maxWidth: theme.breakpoints.values.lg,
            width: '100%',
        },
        sectionContainer: {
            width: '100%',
            paddingBottom: theme.spacing(4),
        },
        featuredVideoSkeletonContainer: {
            position: 'relative',
            overflow: 'hidden',
        },
        featuredVideoSkeleton: {
            paddingTop: ASPECT_RATIOS.videos.teasers.featured,
            [theme.breakpoints.down('xs')]: {
                paddingTop: ASPECT_RATIOS.videos.teasers.square,
            },
            // NOTE: This height forces the aspect ratio box to
            // scale to roughly the same dimensions as the target
            // video thumbnail. This helps prevent CLS
            height: '200px',
        },
        ctaContainer: {
            padding: theme.spacing(3, 0),
        },
    });

export default makeStyles(styles);
