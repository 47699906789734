import React from 'react';
import Filmography, { AllVideos } from './Filmography';
import { VideoMeta } from 'types/media';

const Index = (props: FilmographyIndexPropTypes) => {
    const { featuredVideo, videos, error, keys } = props;

    if (!error) {
        return <Filmography featuredVideo={featuredVideo} videos={videos} keys={keys} />;
    } else {
        console.error(error);
        throw new Error('Error loading Filmography Page content. Please check console');
    }
};

export type FilmographyIndexPropTypes = {
    error?: any;
    keys?: Array<string>;
    featuredVideo: VideoMeta;
    videos?: AllVideos;
};

export default Index;
