import React from 'react';
import Home from './Home';
import { PhotographyAlbum, VideoMeta } from 'types/media';

const Index = (props: HomeIndexPropTypes) => {
    const { featuredVideo, videos, photos, error } = props;

    if (error) {
        console.error(error);
        throw new Error('Error loading Home Page content. Please check console');
    }

    return <Home featuredVideo={featuredVideo} videos={videos} photosAlbums={photos} />;
};

export type HomeIndexPropTypes = {
    featuredVideo?: VideoMeta;
    videos?: Array<VideoMeta>;
    photos?: Array<PhotographyAlbum>;
    error?: any;
};

export default Index;
