import axios from 'axios';
import { SendEmailRequestBody } from 'types/request';

export const sendEmail = async (
    name: string,
    email: string,
    subject: string,
    message: string,
) => {
    const emailRequest: SendEmailRequestBody = {
        name,
        email,
        subject,
        message,
        action: 'collaborate',
    };

    return axios.post(`https://jenasnelling.herokuapp.com/collaborate`, {
        ...emailRequest,
    });
};
