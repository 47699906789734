import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { ASPECT_RATIOS } from 'config/Media';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            scrollBehavior: 'smooth',
        },
        content: {
            width: '100%',
        },
        aboutTheAuthorCard: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(3),
            width: '100%',
        },
        behindTheScenesScrollContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        collaboratorSectionContainer: {
            width: '100%',
            maxWidth: theme.breakpoints.values.lg,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            padding: theme.spacing(4, 0, 6, 0),
        },
        collaboratorTicker: {
            width: '100%',
        },
        ctaContainer: {
            padding: theme.spacing(7, 0),
        },
        featuredVideoSkeletonContainer: {
            position: 'relative',
            overflow: 'hidden',
        },
        featuredVideoSkeleton: {
            paddingTop: ASPECT_RATIOS.videos.teasers.banner,
            [theme.breakpoints.down('xs')]: {
                paddingTop: ASPECT_RATIOS.videos.teasers.square,
            },
            // NOTE: This height forces the aspect ratio box to
            // scale to roughly the same dimensions as the target
            // video thumbnail. This helps prevent CLS
            height: '200px',
        },
        maxWidthContentContainer: {
            margin: '0 auto',
            maxWidth: theme.breakpoints.values.lg,
        },
        stickyTabNavigator: {
            // TODO: Test browswer compatability of this
            position: 'sticky',
            top: '116px',
            zIndex: 10,
        },
        skeletonTabLabelContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50px',
        },
        removeTabPanelPadding: {
            padding: 0,
        },
    });

export default makeStyles(styles);
