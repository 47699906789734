import React from 'react';
import { Tab, useMediaQuery, useTheme } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import TabPanel from '@material-ui/lab/TabPanel';
import { CallToAction } from 'molecules';
import { AlbumTeaserGrid } from 'organisms';
import { PhotographyAlbum } from 'types/media';
import useStyles from './styles';

const Photography = (props: PhotographyPropTypes) => {
    const { albums, keys } = props;

    const classes = useStyles();

    const [artificialLoad, setArtificialLoad] = React.useState(true);
    const [activeTabValue, setActiveTabValue] = React.useState('1');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        let timeout = setTimeout(() => {
            setArtificialLoad(false);
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    React.useEffect(() => {
        try {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            console.warn(
                'Failed to scroll to top on activeTabValueChange. Window may not be defined:',
            );
            console.warn(error);
        }
    }, [activeTabValue]);

    const handleChange = (event: React.ChangeEvent, newValue: string) => {
        setActiveTabValue(newValue);
    };

    const normalizeIndexValue = (index: number) => {
        return (index + 1).toString();
    };

    const renderTabPanel = (
        tabNumber: string,
        title: string,
        albums: PhotographyAlbum[],
    ) => {
        return (
            <TabPanel key={tabNumber} value={tabNumber} className={classes.tabPanel}>
                <AlbumTeaserGrid
                    albums={albums}
                    gridTitle={title === 'all' ? 'All albums' : `Albums about ${title}`}
                    loading={artificialLoad}
                />
            </TabPanel>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <TabContext value={activeTabValue}>
                    <TabList
                        onChange={handleChange}
                        aria-label="Photography categories tab selector"
                        centered={!isMobile}
                        indicatorColor={'primary'}
                        textColor={'primary'}
                        variant={isMobile ? 'fullWidth' : 'standard'}
                        className={classes.stickyTabNavigator}
                    >
                        {keys.map((tabName, index) => (
                            <Tab
                                key={index}
                                label={tabName}
                                value={normalizeIndexValue(index)}
                            />
                        ))}
                    </TabList>
                    {keys.map((categoryName, index) => {
                        const categoryAlbums =
                            albums[categoryName];

                        return renderTabPanel(
                            normalizeIndexValue(index),
                            categoryName,
                            categoryAlbums,
                        );
                    })}
                </TabContext>

                <div className={classes.ctaContainer}>
                    <CallToAction />
                </div>
            </div>
        </div>
    );
};

export type AllAlbums = {
    allAlbums: PhotographyAlbum[];
    lifestyleAlbums: PhotographyAlbum[];
    placesAlbums: PhotographyAlbum[];
    musicAlbums: PhotographyAlbum[];
};

export interface PhotographyPropTypes {
    albums?: { [key: string]: PhotographyAlbum[] };
    keys?: Array<string>;
}

export default Photography;
