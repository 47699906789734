import { ImageMeta, VideoMeta } from 'types/media';
import { CollaboratorResponse } from 'types/response';

export const resourceLocation = process.env.NEXT_PUBLIC_API_RESOURCE_LOCATION;

export const getCollaboratorImages = (): CollaboratorResponse => {
    const collaboratorsFirstRow: Array<ImageMeta> = [
        {
            imageSrc: 'CarletonKoldyke.png',
            altText: 'CarletonKoldyke',
        },
        {
            imageSrc: 'Cellfee.png',
            altText: 'Cellfee',
        },
        {
            imageSrc: 'CircuitMOM.png',
            altText: 'CircuitMOM',
        },
        {
            imageSrc: 'DNA.png',
            altText: 'DNA',
        },
        {
            imageSrc: 'DriveMyWay.png',
            altText: 'DriveMyWay',
        },
        {
            imageSrc: 'EverEvolved.png',
            altText: 'EverEvolved',
        },
        {
            imageSrc: 'FunHouseEntertainment.png',
            altText: 'FunHouseEntertainment',
        },
        {
            imageSrc: 'Khemistry.png',
            altText: 'Khemistry',
        },
        {
            imageSrc: 'Maddawgs.png',
            altText: 'Maddawgs',
        },
    ];

    const collaboratorsSecondRow: Array<ImageMeta> = [
        {
            imageSrc: 'RowdyHouse.png',
            altText: 'RowdyHouse',
        },
        {
            imageSrc: 'SevanMultiSiteSolutions.png',
            altText: 'SevanMultiSiteSolutions',
        },
        {
            imageSrc: 'SinkSlow.png',
            altText: 'SinkSlow',
        },
        {
            imageSrc: 'SofarSounds.png',
            altText: 'SofarSounds',
        },
        {
            imageSrc: 'Spacebones.png',
            altText: 'Spacebones',
        },
        {
            imageSrc: 'TheFundamentalKiNk.png',
            altText: 'TheFundamentalKiNk',
        },
        {
            imageSrc: 'TheGaffes.png',
            altText: 'TheGaffes',
        },
        {
            imageSrc: 'WineChannelTV.png',
            altText: 'WineChannelTV',
        },
    ];

    const collaborators = {
        firstRow: collaboratorsFirstRow,
        secondRow: collaboratorsSecondRow,
    };

    return collaborators;
};

export const getBehindTheScenesImages = (): Array<ImageMeta> => {
    return [
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674535/IMG_4830_pnnggm.jpg',
            altText: 'IMG_4830_pnnggm',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674725/Burrito_BTS_dnqg2w.jpg',
            altText: 'Burrito_BTS_dnqg2w',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674536/Jena-7252_xcgvup.jpg',
            altText: 'Jena',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674535/IMG_7940_qljjru.jpg',
            altText: 'IMG_7940_qljjru',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674535/IMG_7935_hrszhd.jpg',
            altText: 'IMG_7935_hrszhd',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674535/IMG_6288_ddebnd.jpg',
            altText: 'IMG_6288_ddebnd',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674535/IMG_6309_orbimg.jpg',
            altText: 'IMG_6309_orbimg',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674535/IMG_5216_mddfvw.jpg',
            altText: 'IMG_5216_mddfvw',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_3423_ieyvua.jpg',
            altText: 'IMG_3423_ieyvua',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_3437_o1jq0n.jpg',
            altText: 'IMG_3437_o1jq0n',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_3422_fxfqi0.jpg',
            altText: 'IMG_3422_fxfqi0',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_3360_ywnwvj.jpg',
            altText: 'IMG_3360_ywnwvj',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_2620_m6mwho.jpg',
            altText: 'IMG_2620_m6mwho',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_1640_epocuo.jpg',
            altText: 'IMG_1640_epocuo',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_1639_se4zlt.jpg',
            altText: 'IMG_1639_se4zlt',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/DSC02183_zqhq5z.jpg',
            altText: 'DSC02183_zqhq5z',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_0962_fcssc3.jpg',
            altText: 'IMG_0962_fcssc3',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_1569_vjfgi2.jpg',
            altText: 'IMG_1569_vjfgi2',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_0716_gfpach.jpg',
            altText: 'IMG_0716_gfpach',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_0743_ng2vp2.jpg',
            altText: 'IMG_0743_ng2vp2',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674534/IMG_0329_wkyeg9.jpg',
            altText: 'IMG_0329_wkyeg9',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674533/IMG_0324_o3mppm.jpg',
            altText: 'IMG_0324_o3mppm',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674533/GreyArea_BTS_cewl1b.jpg',
            altText: 'GreyArea_BTS_cewl1b',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674533/IMG_0049_j5leyh.jpg',
            altText: 'IMG_0049_j5leyh',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674533/14_t7ldpa.jpg',
            altText: '14_t7ldpa',
        },
        {
            imageSrc:
                'https://res.cloudinary.com/jenasnelling/image/upload/v1608674519/13_s6yprf.jpg',
            altText: '13_s6yprf',
        },
    ];
};
